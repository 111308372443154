import { useEffect, useState } from "react";
import { useParams } from "react-router";
import WritePost from "../components/CommunityCenter/WritePost";
import ErrorModal from "../components/ErrorModal/ErrorModal";
import useHttp from "../hooks/http-hook";

import classes from "./UpdatePost.module.css";

const UpdatePost = () => {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const { isLoading, error, sendRequest, clearError } = useHttp();

  useEffect(() => {
    const getPost = async () => {
      try {
        const { data } = await sendRequest(`/api/v1/posts/${postId}/get-post`);

        setPost(data.post);
      } catch (error) {
        console.log(error);
      }
    };

    getPost();
  }, [postId, sendRequest]);

  return (
    <>
      <ErrorModal error={error} onCancel={clearError} />
      <div className={classes["update-post"]}>
        {post && !isLoading && <WritePost post={post} postId={postId} />}
      </div>
      ;
    </>
  );
};

export default UpdatePost;
