import Card from "../components/UIElements/Card";

import nationalImg from "../assets/img/national.jpg";
import internationalImage from "../assets/img/international.jpg";
import worldImg from "../assets/img/world.jpg";
import customImg from "../assets/img/custom.jpg";
import reviewTableImg from "../assets/img/review-table.jpg";

const testDetails = [
  {
    title: "National",
    description:
      "Train your memory and develop your level with all the National Standard discipline.",
    img: nationalImg,
    path: "national",
  },
  {
    title: "International",
    description:
      "Train your memory and develop your level with all the International Standard discipline.",
    img: internationalImage,
    path: "international",
  },
  {
    title: "World",
    description:
      "Train your memory and develop your level with all the world Standard discipline.",
    img: worldImg,
    path: "world",
  },
  {
    title: "Custom Training",
    description:
      "Train your memory and develop your level in all discipline with Custom Settings.",
    img: customImg,
    path: "custom-training",
  },
  {
    title: "Review Table",
    description: "review the tables in an easy and interactive way.",
    img: reviewTableImg,
    path: "table-review",
  },
];

const Train = () => {
  return (
    <div className="grid">
      {testDetails.map((test) => (
        <Card key={test.title} {...test} />
      ))}
    </div>
  );
};

export default Train;
