import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const TableRow = ({ score, correct, date, index, _id }) => {
  return (
    <tr>
      <td>{index}</td>
      <td>{score}</td>
      <td>{correct}</td>
      <td>{date}</td>
      <td>
        <Link to={`/view-match/${_id}`}>
          View <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </Link>
      </td>
    </tr>
  );
};

export default TableRow;
