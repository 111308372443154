export const cardSuits = [
  "♠♥♣♦",
  "♠♥♦♣",
  "♠♣♥♦",
  "♠♣♦♥",
  "♠♦♣♥",
  "♠♦♥♣",
  "♦♥♣♠",
  "♦♥♠♣",
  "♦♣♥♠",
  "♦♣♠♥",
  "♦♠♣♥",
  "♦♠♥♣",
  "♣♦♥♠",
  "♣♦♠♥",
  "♣♥♦♠",
  "♣♥♠♦",
  "♣♠♥♦",
  "♣♠♦♥",
  "♥♦♣♠",
  "♥♦♠♣",
  "♥♣♦♠",
  "♥♣♠♦",
  "♥♠♣♦",
  "♥♠♦♣",
];
