import classes from "./PreviewImages.module.css";

const PreviewImages = ({ grouping }) => {
  return (
    <div className={classes.preview}>
      {Array.from(Array(grouping * 1 || 1).keys()).map((group, index) => (
        <div key={index} className={classes["img-preview"]}>
          <img
            src={`/public/img/IAM_Images_Database/1 (${index + 1}).png`}
            alt="train-img"
          />
        </div>
      ))}
    </div>
  );
};

export default PreviewImages;
