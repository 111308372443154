import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
} from "react-router-dom";
import CompetitionPage from "./components/Competitions/CompetitionPage";
import Ranking from "./components/Competitions/Ranking";
import CustomTraining from "./components/CustomTraining/CustomTraining";
import Forgot from "./components/ForgotPassword/Forgot";
import PasswordReset from "./components/ForgotPassword/PasswordReset";
import International from "./components/International/International";
import UserData from "./components/MyAccount/UserData";
import MyPerformance from "./components/MyPerformance/MyPerformance";
import National from "./components/National/National";
import Numbers from "./components/Numbers/Numbers";
import OnePostDetails from "./components/OnePostDetails/OnePostDetails";
import PaginationMenu from "./components/PaginationMenu/PaginationMenu";
import TableReview from "./components/TableReview/TableReview";
import World from "./components/World/World";
import {
  calculatePoints10Car,
  calculatePoints15Names,
  calculatePoints15Num,
  calculatePoints30Bin,
  calculatePoints30Car,
  calculatePoints30Num,
  calculatePoints5Bin,
  calculatePoints5Img,
  calculatePoints5Names,
  calculatePoints5Num,
  calculatePoints5Words,
  calculatePoints60Car,
  calculatePoints60Num,
  calculatePointsDates,
  calculatePointsSC,
  calculatePointsSpoken,
} from "./helpers/calculatePoints";
import Chat from "./Pages/Chat";
import Community from "./Pages/Community";
import Competitions from "./Pages/Competitions";
import CreateCompetition from "./Pages/CreateCompetition";
import EmailVerified from "./Pages/EmailVerified";

import ErrorPage from "./Pages/ErrorPage";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import MyAccount from "./Pages/MyAccount";
import PersonalPage from "./Pages/PersonalPage";
import ProtectedRoute from "./Pages/ProtectedRoute";
import RootLayout from "./Pages/RootLayout";
import Train from "./Pages/Train";
import TrainStart from "./Pages/TrainStart";
import UpdatePost from "./Pages/UpdatePost";
import CompetitionPaginationData from "./PaginationData/CompetitionData";
import RankingPaginationData from "./PaginationData/RankingPagination";
import ReviewTable from "./components/Leaderboard/ReviewTable";
import NationalTable from "./components/Leaderboard/NationalTable";
import InternationalTable from "./components/Leaderboard/InternationalTable";
import WorldTable from "./components/Leaderboard/WorldTable";
import Images from "./components/Images/Images";
import ViewMatch from "./components/ViewMatch/ViewMatch";
import Cards from "./components/Cards/Cards";
import Dates from "./components/Dates/Dates";
import Words from "./components/Words/Words";
import Names from "./components/Names/Names";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />} errorElement={<ErrorPage />}>
      <Route index element={<Home />} />

      <Route
        path="ranking"
        element={<PaginationMenu paginationData={RankingPaginationData} />}
      >
        <Route path="national" element={<NationalTable />} />
        <Route path="international" element={<InternationalTable />} />
        <Route path="world" element={<WorldTable />} />
        <Route path="review-table" element={<ReviewTable />} />
      </Route>

      <Route path="login" element={<Login />} />
      <Route path="/:userIdAndToken" element={<EmailVerified />} />
      <Route path="forgot-password" element={<Forgot />} />
      <Route path="/reset-password/:resetToken" element={<PasswordReset />} />
      <Route
        element={
          <ProtectedRoute>
            <MyAccount />
          </ProtectedRoute>
        }
      >
        <Route
          path="my-account"
          element={
            <ProtectedRoute>
              <UserData />
            </ProtectedRoute>
          }
        />
        <Route
          path="my-performance"
          element={
            <ProtectedRoute>
              <MyPerformance />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route
        path="community"
        element={
          <ProtectedRoute>
            <Community />
          </ProtectedRoute>
        }
      />

      <Route
        path="/community/:userId"
        element={
          <ProtectedRoute>
            <PersonalPage />
          </ProtectedRoute>
        }
      />

      <Route
        path="/community/chat"
        element={
          <ProtectedRoute>
            <Chat />
          </ProtectedRoute>
        }
      />

      <Route
        path="/community/posts/:postId"
        element={
          <ProtectedRoute>
            <OnePostDetails />
          </ProtectedRoute>
        }
      />

      <Route
        path="/community/update/:postId"
        element={
          <ProtectedRoute>
            <UpdatePost />
          </ProtectedRoute>
        }
      />

      {/* Train Routes */}
      <Route
        path="train"
        element={
          <ProtectedRoute>
            <Train />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/table-review"
        element={
          <ProtectedRoute>
            <TableReview />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/table-review/object-one"
        element={
          <ProtectedRoute>
            <TrainStart title="Object One" />
          </ProtectedRoute>
        }
      />
      <Route
        path="train/table-review/object-two"
        element={
          <ProtectedRoute>
            <TrainStart title="Object Two" />
          </ProtectedRoute>
        }
      />
      <Route
        path="train/table-review/action"
        element={
          <ProtectedRoute>
            <TrainStart title="Action" />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/national"
        element={
          <ProtectedRoute>
            <National />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/national/speed-numbers"
        element={
          <ProtectedRoute>
            <Numbers
              memoTime={5}
              recallTime={15}
              title={"Speed Numbers"}
              amount={760}
              numberBerRow={40}
              factor={10}
              rowInPage={6}
              calculatePoints={calculatePoints5Num}
              standard={"National"}
              type={"numbers"}
              defaultGrouping={"22"}
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="train/national/15-mins-numbers"
        element={
          <ProtectedRoute>
            <Numbers
              memoTime={15}
              recallTime={30}
              title={"15-Minutes Numbers"}
              amount={1560}
              numberBerRow={40}
              factor={10}
              rowInPage={6}
              calculatePoints={calculatePoints15Num}
              standard={"National"}
              type={"numbers"}
              defaultGrouping={"22"}
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="train/national/5-mins-binaries"
        element={
          <ProtectedRoute>
            <Numbers
              memoTime={5}
              recallTime={15}
              title={"5-Minutes Binaries"}
              amount={1530}
              numberBerRow={30}
              factor={2}
              rowInPage={6}
              calculatePoints={calculatePoints5Bin}
              standard={"National"}
              type={"binary"}
              defaultGrouping={"33"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/national/spoken-numbers"
        element={
          <ProtectedRoute>
            <Numbers
              memoTime={
                (localStorage.getItem("spokenAmount") * 1 + 10) / 60 || 110 / 60
              }
              recallTime={
                localStorage.getItem(`spokenAttempt`) === "2"
                  ? 15
                  : localStorage.getItem(`spokenAttempt`) === "3"
                  ? 25
                  : 5
              }
              title={"Spoken Numbers"}
              amount={localStorage.getItem("spokenAmount") * 1 || 100}
              numberBerRow={30}
              factor={10}
              rowInPage={6}
              calculatePoints={calculatePointsSpoken}
              standard={"National"}
              type={"spoken"}
              defaultGrouping={"22"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/national/images"
        element={
          <ProtectedRoute>
            <Images
              memoTime={5}
              recallTime={20}
              title={"Images"}
              amount={785}
              numberBerRow={5}
              rowInPage={3}
              calculatePoints={calculatePoints5Img}
              standard={"National"}
              type={"images"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/national/10-mins-cards"
        element={
          <ProtectedRoute>
            <Cards
              memoTime={10}
              recallTime={30}
              title={"10-Minutes Cards"}
              amount={13}
              numberBerRow={52}
              rowInPage={1}
              calculatePoints={calculatePoints10Car}
              standard={"National"}
              type={"cards"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/national/speed-cards"
        element={
          <ProtectedRoute>
            <Cards
              memoTime={5}
              recallTime={5}
              title={"Speed Cards"}
              amount={1}
              numberBerRow={52}
              rowInPage={1}
              calculatePoints={calculatePointsSC}
              standard={"National"}
              type={"speedCards"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/national/dates"
        element={
          <ProtectedRoute>
            <Dates
              memoTime={5}
              recallTime={15}
              title={"Dates"}
              amount={188}
              numberBerRow={1}
              rowInPage={10}
              calculatePoints={calculatePointsDates}
              standard={"National"}
              type={"dates"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/national/5-mins-words"
        element={
          <ProtectedRoute>
            <Words
              memoTime={5}
              recallTime={15}
              title={"5-Minutes Words"}
              amount={180}
              numberBerRow={20}
              rowInPage={3}
              calculatePoints={calculatePoints5Words}
              standard={"National"}
              type={"words"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/national/5-mins-names"
        element={
          <ProtectedRoute>
            <Names
              memoTime={5}
              recallTime={15}
              title={"5-Minutes Names"}
              amount={63}
              numberBerRow={3}
              rowInPage={2}
              calculatePoints={calculatePoints5Names}
              standard={"National"}
              type={"names"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/international"
        element={
          <ProtectedRoute>
            <International />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/international/speed-numbers"
        element={
          <ProtectedRoute>
            <Numbers
              memoTime={5}
              recallTime={15}
              title={"Speed Numbers"}
              amount={760}
              numberBerRow={40}
              factor={10}
              rowInPage={6}
              calculatePoints={calculatePoints5Num}
              standard={"International"}
              type={"numbers"}
              defaultGrouping={"22"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/international/30-mins-numbers"
        element={
          <ProtectedRoute>
            <Numbers
              memoTime={30}
              recallTime={60}
              title={"30-Minutes Numbers"}
              amount={2360}
              numberBerRow={40}
              factor={10}
              rowInPage={6}
              calculatePoints={calculatePoints30Num}
              standard={"International"}
              type={"numbers"}
              defaultGrouping={"22"}
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="train/international/30-mins-binaries"
        element={
          <ProtectedRoute>
            <Numbers
              memoTime={30}
              recallTime={60}
              title={"30-Minutes Binaries"}
              amount={7530}
              numberBerRow={30}
              factor={2}
              rowInPage={6}
              calculatePoints={calculatePoints30Bin}
              standard={"International"}
              type={"binary"}
              defaultGrouping={"33"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/international/spoken-numbers"
        element={
          <ProtectedRoute>
            <Numbers
              memoTime={
                (localStorage.getItem("spokenAmount") * 1 + 10) / 60 || 110 / 60
              }
              recallTime={
                localStorage.getItem(`spokenAttempt`) === "2"
                  ? 15
                  : localStorage.getItem(`spokenAttempt`) === "3"
                  ? 25
                  : 5
              }
              title={"Spoken Numbers"}
              amount={localStorage.getItem("spokenAmount") * 1 || 100}
              numberBerRow={30}
              factor={10}
              rowInPage={6}
              calculatePoints={calculatePointsSpoken}
              standard={"International"}
              type={"spoken"}
              defaultGrouping={"22"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/international/images"
        element={
          <ProtectedRoute>
            <Images
              memoTime={5}
              recallTime={20}
              title={"Images"}
              amount={785}
              numberBerRow={5}
              rowInPage={3}
              calculatePoints={calculatePoints5Img}
              standard={"International"}
              type={"images"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/international/30-mins-cards"
        element={
          <ProtectedRoute>
            <Cards
              memoTime={30}
              recallTime={60}
              title={"30-Minutes Cards"}
              amount={28}
              numberBerRow={52}
              rowInPage={1}
              calculatePoints={calculatePoints30Car}
              standard={"International"}
              type={"cards"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/international/speed-cards"
        element={
          <ProtectedRoute>
            <Cards
              memoTime={5}
              recallTime={5}
              title={"Speed Cards"}
              amount={1}
              numberBerRow={52}
              rowInPage={1}
              calculatePoints={calculatePointsSC}
              standard={"International"}
              type={"speedCards"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/international/dates"
        element={
          <ProtectedRoute>
            <Dates
              memoTime={5}
              recallTime={15}
              title={"Dates"}
              amount={188}
              numberBerRow={1}
              rowInPage={10}
              calculatePoints={calculatePointsDates}
              standard={"International"}
              type={"dates"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/international/15-mins-words"
        element={
          <ProtectedRoute>
            <Words
              memoTime={15}
              recallTime={40}
              title={"15-Minutes Words"}
              amount={400}
              numberBerRow={20}
              rowInPage={3}
              calculatePoints={calculatePoints5Words}
              standard={"International"}
              type={"words"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/international/15-mins-names"
        element={
          <ProtectedRoute>
            <Names
              memoTime={15}
              recallTime={30}
              title={"15-Minutes Names"}
              amount={134}
              numberBerRow={3}
              rowInPage={2}
              calculatePoints={calculatePoints15Names}
              standard={"International"}
              type={"names"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/world"
        element={
          <ProtectedRoute>
            <World />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/world/speed-numbers"
        element={
          <ProtectedRoute>
            <Numbers
              memoTime={5}
              recallTime={15}
              title={"Speed Numbers"}
              amount={760}
              numberBerRow={40}
              factor={10}
              rowInPage={6}
              calculatePoints={calculatePoints5Num}
              standard={"World"}
              type={"numbers"}
              defaultGrouping={"22"}
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="train/world/hour-numbers"
        element={
          <ProtectedRoute>
            <Numbers
              memoTime={60}
              recallTime={120}
              title={"Hour Numbers"}
              amount={3920}
              numberBerRow={40}
              factor={10}
              rowInPage={6}
              calculatePoints={calculatePoints60Num}
              standard={"World"}
              type={"numbers"}
              defaultGrouping={"22"}
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="train/world/30-mins-binaries"
        element={
          <ProtectedRoute>
            <Numbers
              memoTime={30}
              recallTime={60}
              title={"30-Minutes Binaries"}
              amount={7530}
              numberBerRow={30}
              factor={2}
              rowInPage={6}
              calculatePoints={calculatePoints30Bin}
              standard={"World"}
              type={"binary"}
              defaultGrouping={"33"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/world/spoken-numbers"
        element={
          <ProtectedRoute>
            <Numbers
              memoTime={
                (localStorage.getItem("spokenAmount") * 1 + 10) / 60 || 110 / 60
              }
              recallTime={
                localStorage.getItem(`spokenAttempt`) === "2"
                  ? 15
                  : localStorage.getItem(`spokenAttempt`) === "3"
                  ? 25
                  : 5
              }
              title={"Spoken Numbers"}
              amount={localStorage.getItem("spokenAmount") * 1 || 100}
              numberBerRow={30}
              factor={10}
              rowInPage={6}
              calculatePoints={calculatePointsSpoken}
              standard={"World"}
              type={"spoken"}
              defaultGrouping={"22"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/world/images"
        element={
          <ProtectedRoute>
            <Images
              memoTime={5}
              recallTime={20}
              title={"Images"}
              amount={785}
              numberBerRow={5}
              rowInPage={3}
              calculatePoints={calculatePoints5Img}
              standard={"World"}
              type={"images"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/world/hour-cards"
        element={
          <ProtectedRoute>
            <Cards
              memoTime={60}
              recallTime={120}
              title={"Hour Cards"}
              amount={43}
              numberBerRow={52}
              rowInPage={1}
              calculatePoints={calculatePoints60Car}
              standard={"World"}
              type={"cards"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/world/speed-cards"
        element={
          <ProtectedRoute>
            <Cards
              memoTime={5}
              recallTime={5}
              title={"Speed Cards"}
              amount={1}
              numberBerRow={52}
              rowInPage={1}
              calculatePoints={calculatePointsSC}
              standard={"World"}
              type={"speedCards"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/world/dates"
        element={
          <ProtectedRoute>
            <Dates
              memoTime={5}
              recallTime={15}
              title={"Dates"}
              amount={188}
              numberBerRow={1}
              rowInPage={10}
              calculatePoints={calculatePointsDates}
              standard={"World"}
              type={"dates"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/world/15-mins-words"
        element={
          <ProtectedRoute>
            <Words
              memoTime={15}
              recallTime={40}
              title={"15-Minutes Words"}
              amount={400}
              numberBerRow={20}
              rowInPage={3}
              calculatePoints={calculatePoints5Words}
              standard={"World"}
              type={"words"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/world/15-mins-names"
        element={
          <ProtectedRoute>
            <Names
              memoTime={15}
              recallTime={30}
              title={"15-Minutes Names"}
              amount={134}
              numberBerRow={3}
              rowInPage={2}
              calculatePoints={calculatePoints15Names}
              standard={"World"}
              type={"names"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/custom-training"
        element={
          <ProtectedRoute>
            <CustomTraining />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/custom-training/numbers"
        element={
          <ProtectedRoute>
            <Numbers
              memoTime={localStorage.getItem("numbersMemo") * 1 || 1}
              recallTime={localStorage.getItem("numbersRecall") * 1 || 2}
              title={"Custom Numbers"}
              amount={localStorage.getItem("numbersAmount") * 1 || 100}
              numberBerRow={40}
              factor={10}
              rowInPage={6}
              custom={true}
              standard={"Custom"}
              type={"numbers"}
              defaultGrouping={"22"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/custom-training/binaries"
        element={
          <ProtectedRoute>
            <Numbers
              memoTime={localStorage.getItem("binaryMemo") * 1 || 1}
              recallTime={localStorage.getItem("binaryRecall") * 1 || 2}
              amount={localStorage.getItem("binaryAmount") * 1 || 100}
              title={"Custom Binaries"}
              numberBerRow={30}
              factor={2}
              rowInPage={6}
              custom={true}
              standard={"Custom"}
              type={"binary"}
              defaultGrouping={"33"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/custom-training/spoken-numbers"
        element={
          <ProtectedRoute>
            <Numbers
              memoTime={
                localStorage.getItem("spokenCustomAmount") * 1 + 10 || 110 / 60
              }
              recallTime={localStorage.getItem(`spokenCustomRecall`) * 1 || 5}
              amount={localStorage.getItem("spokenCustomAmount") * 1 || 100}
              title={"Custom Spoken Numbers"}
              numberBerRow={30}
              factor={10}
              rowInPage={6}
              calculatePoints={calculatePointsSpoken}
              standard={"Custom"}
              custom={true}
              type={"spoken"}
              defaultGrouping={"22"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/custom-training/images"
        element={
          <ProtectedRoute>
            <Images
              memoTime={localStorage.getItem("imagesMemo") * 1 || 1}
              recallTime={localStorage.getItem("imagesRecall") * 1 || 2}
              amount={localStorage.getItem("imagesAmount") * 1 || 50}
              title={"Custom Images"}
              numberBerRow={5}
              rowInPage={3}
              standard={"Custom"}
              custom={true}
              type={"images"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/custom-training/cards"
        element={
          <ProtectedRoute>
            <Cards
              memoTime={localStorage.getItem("cardsMemo") * 1 || 1}
              recallTime={localStorage.getItem("cardsRecall") * 1 || 2}
              amount={
                Math.ceil((localStorage.getItem("cardsAmount") * 1) / 52) || 2
              }
              title={"Custom Cards"}
              numberBerRow={52}
              rowInPage={1}
              standard={"Custom"}
              custom={true}
              type={"cards"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/custom-training/dates"
        element={
          <ProtectedRoute>
            <Dates
              memoTime={localStorage.getItem("datesMemo") * 1 || 1}
              recallTime={localStorage.getItem("datesRecall") * 1 || 2}
              amount={localStorage.getItem("datesAmount") * 1 || 10}
              title={"Custom Dates"}
              numberBerRow={1}
              rowInPage={10}
              standard={"Custom"}
              custom={true}
              type={"dates"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/custom-training/words"
        element={
          <ProtectedRoute>
            <Words
              memoTime={localStorage.getItem("wordsMemo") * 1 || 1}
              recallTime={localStorage.getItem("wordsRecall") * 1 || 2}
              amount={localStorage.getItem("wordsAmount") * 1 || 20}
              title={"Custom Words"}
              numberBerRow={20}
              rowInPage={3}
              standard={"Custom"}
              custom={true}
              type={"words"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="train/custom-training/names"
        element={
          <ProtectedRoute>
            <Names
              memoTime={localStorage.getItem("namesMemo") * 1 || 1}
              recallTime={localStorage.getItem("namesRecall") * 1 || 2}
              amount={localStorage.getItem("namesAmount") * 1 || 20}
              title={"Custom Names"}
              numberBerRow={3}
              rowInPage={2}
              standard={"Custom"}
              custom={true}
              type={"names"}
              defaultGrouping={"1"}
            />
          </ProtectedRoute>
        }
      />

      <Route path="competitions" element={<Competitions />} />

      <Route
        path="competitions/:slug/:season"
        element={<PaginationMenu paginationData={CompetitionPaginationData} />}
      >
        <Route path="overview" element={<CompetitionPage />} />
        <Route path="ranking" element={<Ranking />} />
      </Route>

      <Route
        path="competitions/create-competition"
        element={
          <ProtectedRoute>
            <CreateCompetition />
          </ProtectedRoute>
        }
      />

      <Route
        path="competitions/update-competition/:slug/:season"
        element={
          <ProtectedRoute>
            <CreateCompetition />
          </ProtectedRoute>
        }
      />

      <Route path="view-match/:disciplineId" element={<ViewMatch />} />
    </Route>
  )
);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
