import {
  calculatePoints10Car,
  calculatePoints15Names,
  calculatePoints15Num,
  calculatePoints15Words,
  calculatePoints30Bin,
  calculatePoints30Car,
  calculatePoints30Num,
  calculatePoints5Bin,
  calculatePoints5Img,
  calculatePoints5Names,
  calculatePoints5Num,
  calculatePoints5Words,
  calculatePoints60Car,
  calculatePoints60Num,
  calculatePointsDates,
  calculatePointsSC,
  calculatePointsSpoken,
} from "./calculatePoints";

export const calculatePointsObj = {
  "Speed Numbers": calculatePoints5Num,
  "15-Minutes Numbers": calculatePoints15Num,
  "30-Minutes Numbers": calculatePoints30Num,
  "Hour Numbers": calculatePoints60Num,
  "Spoken Numbers": calculatePointsSpoken,
  "5-Minutes Binaries": calculatePoints5Bin,
  "30-Minutes Binaries": calculatePoints30Bin,
  Images: calculatePoints5Img,
  Dates: calculatePointsDates,
  "10-Minutes Cards": calculatePoints10Car,
  "30-Minutes Cards": calculatePoints30Car,
  "Hour Cards": calculatePoints60Car,
  "Speed Cards": calculatePointsSC,
  "5-Minutes Words": calculatePoints5Words,
  "15-Minutes Words": calculatePoints15Words,
  "5-Minutes Names": calculatePoints5Names,
  "15-Minutes Names": calculatePoints15Names,
};
