export const ReviewTablePagination = [
  {
    title: "Object One",
    shortTitle: "Object One",
  },
  {
    title: "Object Two",
    shortTitle: "Object Two",
  },
  {
    title: "Action",
    shortTitle: "Action",
  },
];
