import { useEffect, useState } from "react";
import { useParams } from "react-router";
import useHttp from "../../hooks/http-hook";

import ErrorModal from "../ErrorModal/ErrorModal";
import CompetitionInfo from "./CompetitionInfo";

import Participants from "./Participants";
import ContentEnter from "../UIElements/ContentEnter";

import classes from "./CompetitionPage.module.css";

const CompetitionPage = () => {
  const { slug, season } = useParams();
  const [loading, setLoading] = useState(true);
  const [competition, setCompetition] = useState({});
  const { error, sendRequest, clearError } = useHttp();

  // get competition if mode is edit
  useEffect(() => {
    const getCompetition = async () => {
      try {
        const { data } = await sendRequest(
          `/api/v1/competitions/${slug}/${season}`
        );

        setCompetition(data.competition);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getCompetition();
  }, [sendRequest, slug, season]);

  return (
    <>
      <ErrorModal error={error} onCancel={clearError} />

      <ContentEnter show={!loading}>
        <div className={classes["competition-page"]}>
          <CompetitionInfo
            competition={competition}
            setCompetition={setCompetition}
          />

          <ContentEnter
            show={
              competition.participants && competition.participants.length > 0
            }
          >
            <Participants participants={competition.participants} />
          </ContentEnter>
        </div>
      </ContentEnter>
    </>
  );
};

export default CompetitionPage;
