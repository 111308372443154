import { memo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { format } from "timeago.js";
import { checkOnlineStatusHandler } from "../../helpers/checkOnlineStatus";

import classes from "./ChatBoxHead.module.css";

const ChatBoxHead = () => {
  const { onlineUsers, activeChat, activeFriend } = useSelector(
    (state) => state.chat
  );

  const { user } = useSelector((state) => state.auth);
  const onlineStatus = checkOnlineStatusHandler(activeChat, onlineUsers, user);

  return (
    <div className={classes.head}>
      <div
        className={`${classes.img} ${
          onlineStatus ? classes.online : undefined
        }`}
      >
        <img
          crossOrigin="anonymous"
          src={
            activeFriend?.photo?.secure_url ||
            `/public/img/usersImages/default.jpg`
          }
          alt={activeFriend.name}
        />
      </div>

      <div className={classes["friend-info"]}>
        <h3>
          <Link to={`/community/${activeFriend._id}`}>{activeFriend.name}</Link>
        </h3>
        <span>Friends Since {format(activeChat.createdAt)}.</span>
      </div>
    </div>
  );
};

export default memo(ChatBoxHead);
