import { faHeart, faComment, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHttp from "../../hooks/http-hook";
import ErrorModal from "../ErrorModal/ErrorModal";
import PostActivityDetails from "./PostActivityDetails";
import { replacePost } from "../../store/postSlice";

import classes from "./PostActivity.module.css";

const PostActivity = ({
  likes,
  postId,
  getPostCommentsHandler,
  numberOfComments,
  setShowComments,
  showComments,
  posts,
}) => {
  const { user } = useSelector((state) => state.auth);
  const [loved, setLoved] = useState(likes.includes(user._id));
  const [likesNum, setLikesNum] = useState(likes.length);
  const { error, sendRequest, clearError } = useHttp();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoved(likes.includes(user._id));
  }, [user._id, likes]);

  useEffect(() => {
    setLikesNum(likes.length);
  }, [likes]);

  const lovedHandler = async () => {
    setLoved((prev) => !prev);
    try {
      const { data } = await sendRequest(
        `/api/v1/posts/${postId}/like`,
        "PATCH"
      );
      loved ? setLikesNum((prev) => prev - 1) : setLikesNum((prev) => prev + 1);

      // update post in the state after add like
      const postIndex = posts.findIndex((post) => post._id === postId);
      dispatch(replacePost({ postIndex, newPost: data.newPost }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ErrorModal error={error} onCancel={clearError} />

      <PostActivityDetails
        likes={likesNum}
        numberOfComments={numberOfComments}
      />
      <div className={classes.activity}>
        <div
          className={loved ? classes.loved : undefined}
          onClick={lovedHandler}
        >
          <FontAwesomeIcon icon={faHeart} />
          {loved ? "Liked" : "Like"}
        </div>

        <div
          onClick={() => {
            if (showComments) {
              setShowComments(false);
              return;
            }

            getPostCommentsHandler();
          }}
        >
          <FontAwesomeIcon icon={faComment} />
          comment
        </div>

        <div>
          <FontAwesomeIcon icon={faShare} />
          share
        </div>
      </div>
    </>
  );
};

export default PostActivity;
