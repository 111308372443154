import { useEffect, useState } from "react";
import { useParams } from "react-router";
import useHttp from "../../hooks/http-hook";
import Post from "../CommunityCenter/Post";
import ErrorModal from "../ErrorModal/ErrorModal";

import classes from "./OnePostDetails.module.css";

const OnePostDetails = () => {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const { error, sendRequest, clearError } = useHttp();

  useEffect(() => {
    const getPost = async () => {
      const { data } = await sendRequest(`/api/v1/posts/${postId}/get-post`);

      setPost(data.post);
    };

    getPost();
  }, [sendRequest, postId]);

  return (
    <>
      <ErrorModal error={error} onCancel={clearError} />

      {post && (
        <div className={classes["one-post-details"]}>
          {post && <Post {...post} onePostDetails={true} onePost={true} />}
        </div>
      )}
    </>
  );
};

export default OnePostDetails;
