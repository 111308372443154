import { Link } from "react-router-dom";

import classes from "./ViewMore.module.css";
import { memo } from "react";

const ViewMore = ({ children, onClick, to }) => {
  return to ? (
    <Link to={to} className={classes["view-more"]}>
      {children}
    </Link>
  ) : (
    <button onClick={onClick} className={classes["view-more"]}>
      {children}
    </button>
  );
};

export default memo(ViewMore);
