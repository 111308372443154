const DatesInstructions = ({ numberBerRow }) => {
  return (
    <td>
      * {numberBerRow} date/row.
      <br />* More than 2 mistake -0.5 each mistake.
    </td>
  );
};

export default DatesInstructions;
