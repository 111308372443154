export const AllDiscipline = [
  //   {
  //     title: "Overall",
  //     shortTitle: "Overall",
  //   },
  {
    title: "Speed Numbers",
    shortTitle: "5-Numbers",
  },
  {
    title: "15-Minutes Numbers",
    shortTitle: "15-Numbers",
  },
  {
    title: "30-Minutes Numbers",
    shortTitle: "30-Numbers",
  },
  {
    title: "Hour Numbers",
    shortTitle: "60-Numbers",
  },
  {
    title: "5-Minutes Binaries",
    shortTitle: "5-Binaries",
  },
  {
    title: "30-Minutes Binaries",
    shortTitle: "30-Binaries",
  },
  {
    title: "5-Minutes Names",
    shortTitle: "5-Names",
  },
  {
    title: "15-Minutes Names",
    shortTitle: "15-Names",
  },
  {
    title: "Dates",
    shortTitle: "Dates",
  },
  {
    title: "10-Minutes Cards",
    shortTitle: "10-Cards",
  },
  {
    title: "30-Minutes Cards",
    shortTitle: "30-Cards",
  },
  {
    title: "Hour Cards",
    shortTitle: "Hour Cards",
  },
  {
    title: "Images",
    shortTitle: "Images",
  },
  {
    title: "5-Minutes Words",
    shortTitle: "5-Words",
  },
  {
    title: "15-Minutes Words",
    shortTitle: "15-Words",
  },
  {
    title: "Spoken Numbers",
    shortTitle: "Spoken",
  },
  {
    title: "Speed Cards",
    shortTitle: "Speed Cards",
  },
  {
    title: "Custom Numbers",
    shortTitle: "Numbers",
  },
  {
    title: "Custom Binaries",
    shortTitle: "Binaries",
  },
  {
    title: "Custom Spoken Numbers",
    shortTitle: "Spoken",
  },
  {
    title: "Custom Images",
    shortTitle: "Images",
  },
  {
    title: "Custom Cards",
    shortTitle: "Cards",
  },
  {
    title: "Custom Dates",
    shortTitle: "Dates",
  },
  {
    title: "Custom Words",
    shortTitle: "Words",
  },
  {
    title: "Custom Names",
    shortTitle: "Names",
  },
];
