import {
  faBackwardFast,
  faMinusSquare,
  faPlusSquare,
  faSquareCaretLeft,
  faSquareCaretRight,
  faXmarkSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import classes from "./Arrows.module.css";

const Arrows = ({
  forwardToNextPosition,
  backToLastPosition,
  backToFirst,
  memo,
  shiftForward,
  shiftBackward,
  deleteCurrentNumber,
  shifting,
}) => {
  return (
    <div className={classes.show}>
      <div className={classes.arrows}>
        <span onClick={backToFirst}>
          <FontAwesomeIcon icon={faBackwardFast} />
        </span>
        <span onClick={backToLastPosition}>
          <FontAwesomeIcon icon={faSquareCaretLeft} />
        </span>
        <span onClick={forwardToNextPosition}>
          <FontAwesomeIcon icon={faSquareCaretRight} />
        </span>

        {!memo && (
          <>
            {shifting && (
              <>
                <span onClick={shiftForward}>
                  <FontAwesomeIcon icon={faPlusSquare} />
                </span>

                <span onClick={shiftBackward}>
                  <FontAwesomeIcon icon={faMinusSquare} />
                </span>
              </>
            )}

            <span onClick={deleteCurrentNumber}>
              <FontAwesomeIcon icon={faXmarkSquare} />
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default Arrows;
