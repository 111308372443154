export const DisciplineInfo = {
  "Speed Numbers": {
    numberBerRow: 40,
    rowInPage: 6,
  },
  "15-Minutes Numbers": {
    numberBerRow: 40,
    rowInPage: 6,
  },
  "Custom Numbers": {
    numberBerRow: 40,
    rowInPage: 6,
  },
  "Spoken Numbers": {
    numberBerRow: 30,
    rowInPage: 6,
  },
  "Custom Spoken Numbers": {
    numberBerRow: 30,
    rowInPage: 6,
  },
  "5-Minutes Binaries": {
    numberBerRow: 30,
    rowInPage: 6,
  },
  "Custom Binaries": {
    numberBerRow: 30,
    rowInPage: 6,
  },
  "30-Minutes Numbers": {
    numberBerRow: 40,
    rowInPage: 6,
  },
  "30-Minutes Binaries": {
    numberBerRow: 30,
    rowInPage: 6,
  },
  "Hour Numbers": {
    numberBerRow: 40,
    rowInPage: 6,
  },
  Images: {
    numberBerRow: 5,
    rowInPage: 3,
  },
  "Custom Images": {
    numberBerRow: 5,
    rowInPage: 3,
  },

  "Speed Cards": {
    numberBerRow: 52,
    rowInPage: 1,
  },

  "10-Minutes Cards": {
    numberBerRow: 52,
    rowInPage: 1,
  },

  "Custom Cards": {
    numberBerRow: 52,
    rowInPage: 1,
  },

  "30-Minutes Cards": {
    numberBerRow: 52,
    rowInPage: 1,
  },

  "Hour Cards": {
    numberBerRow: 52,
    rowInPage: 1,
  },

  Dates: {
    numberBerRow: 1,
    rowInPage: 10,
  },

  "Custom Dates": {
    numberBerRow: 1,
    rowInPage: 10,
  },

  "5-Minutes Words": {
    numberBerRow: 20,
    rowInPage: 3,
  },

  "15-Minutes Words": {
    numberBerRow: 20,
    rowInPage: 3,
  },

  "Custom Words": {
    numberBerRow: 20,
    rowInPage: 3,
  },

  "5-Minutes Names": {
    numberBerRow: 3,
    rowInPage: 2,
  },

  "15-Minutes Names": {
    numberBerRow: 3,
    rowInPage: 2,
  },

  "Custom Names": {
    numberBerRow: 3,
    rowInPage: 2,
  },
};
