import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import classes from "./PaginationMenu.module.css";

const PaginationMenu = ({ paginationData }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // التحقق من أننا في صفحة Ranking
    if (location.pathname === "/ranking") {
      // تحديث route إلى "/ranking/national"
      navigate("/ranking/national");
    }
  }, [location.pathname, navigate]);

  return (
    <>
      <div className={classes["pagination-menu"]}>
        <div>
          <ul className={classes["pagination-menu-ul"]}>
            {paginationData.map((el) => (
              <li key={el.title}>
                <NavLink
                  to={el.to}
                  className={({ isActive }) =>
                    isActive ? classes.active : undefined
                  }
                >
                  {el.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>

        <Outlet />
      </div>
    </>
  );
};

export default PaginationMenu;
