const SpokenInstructions = () => {
  return (
    <td>
      * The program will spoke 1 number each second.
      <br />* Score will count upto first mistake
    </td>
  );
};

export default SpokenInstructions;
