import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setMyCompetitions } from "../store/resultSlice";
import useHttp from "./http-hook";

const useMyCompetitions = (standard) => {
  const [standardLoading, setStandardLoading] = useState(true);
  const { sendRequest } = useHttp();
  const dispatch = useDispatch();

  useEffect(() => {
    const getMyCompetitions = async () => {
      try {
        const { data } = await sendRequest(
          `/api/v1/competitions/my-competitions/${standard}`
        );

        dispatch(setMyCompetitions(data.competitions));
      } catch (error) {
        console.log(error);
      }

      setStandardLoading(false);
    };

    getMyCompetitions();
  }, [sendRequest, standard, dispatch]);

  return { standardLoading };
};

export default useMyCompetitions;
