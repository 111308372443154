import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommunityBar from "../components/CommunityBar/CommunityBar";
import Center from "../components/CommunityCenter/Center";
import Left from "../components/CommunityLeft/Left";
import Right from "../components/CommunityRight/Right";
import ErrorModal from "../components/ErrorModal/ErrorModal";
import { socket } from "../helpers/socket";
import useHttp from "../hooks/http-hook";
import { setChats, setOnlineUsers } from "../store/chatSlice";
import ContentEnter from "../components/UIElements/ContentEnter";

import classes from "./Community.module.css";

const Community = () => {
  const { user } = useSelector((state) => state.auth);
  const { error, sendRequest, clearError } = useHttp();

  const dispatchRedux = useDispatch();

  // get all chats
  useEffect(() => {
    const getChats = async () => {
      try {
        const { data } = await sendRequest(`/api/v1/chats`);

        dispatchRedux(setChats(data.chats));
      } catch (error) {
        console.log(error);
      }
    };

    getChats();
  }, [sendRequest, dispatchRedux]);

  // connect with socket at backend
  useEffect(() => {
    // send userId to socket to add to active users and back with active users
    socket.emit("new-user-add", user._id);

    socket.on("get-users", (users) => {
      dispatchRedux(setOnlineUsers(users));
    });
  }, [user, dispatchRedux]);

  const [currentPage, setCurrentPage] = useState(2);

  const navigateToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <ErrorModal error={error} onCancel={clearError} />

      <div className={classes.mainCommunity}>
        <div className={`${classes.community} ${classes["community-big"]}`}>
          <Left />
          <Center />
          <Right />
        </div>

        <div className={`${classes["community-sm"]} ${classes.community}`}>
          <CommunityBar
            navigateToPage={navigateToPage}
            currentPage={currentPage}
          />

          <ContentEnter show={currentPage === 1}>
            <Left />
          </ContentEnter>

          <ContentEnter show={currentPage === 2}>
            <Center />
          </ContentEnter>

          <ContentEnter show={currentPage === 3}>
            <Right />
          </ContentEnter>
        </div>
      </div>
    </>
  );
};

export default Community;
