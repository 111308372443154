import {
  faImage,
  faPaperPlane,
  faRectangleXmark,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { memo, useRef, useState } from "react";
import InputEmoji from "react-input-emoji";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import classes from "./CreateDocsForm.module.css";

const CreateDocForm = ({
  createDocHandler,
  isLoading,
  placeholder,
  onFocus,
}) => {
  const [text, setText] = useState("");
  const [commentImage, setCommentImage] = useState(null);
  const imageRef = useRef();

  const changeImageHandler = (e) => {
    if (e.target.files || e.target.files[0]) setCommentImage(e.target.files[0]);
  };

  return (
    <>
      <div
        className={`${classes.comment} ${
          text.trim().length < 1 ? undefined : classes["valid-comment"]
        }`}
      >
        <div onClick={() => imageRef.current.click()}>
          <FontAwesomeIcon icon={faImage} />
        </div>

        <div>
          <InputEmoji
            value={text}
            placeholder={placeholder}
            onChange={setText}
            borderColor="transparent"
            fontSize={15}
            onEnter={() => {
              createDocHandler(text, commentImage);
              setText("");
              setCommentImage(null);
            }}
            onFocus={onFocus}
            height={20}
            borderRadius={3}
            padding={5}
          />
        </div>

        <div>
          <button
            className={classes.button}
            disabled={(text.trim().length < 1 && !commentImage) || isLoading}
            onClick={() => {
              createDocHandler(text, setCommentImage);
              setText("");
              setCommentImage(null);
            }}
          >
            {!isLoading ? (
              <FontAwesomeIcon icon={faPaperPlane} />
            ) : (
              <FontAwesomeIcon icon={faSpinner} />
            )}
          </button>
        </div>

        <div style={{ display: "none" }}>
          <input
            type="file"
            name="postImage"
            ref={imageRef}
            onChange={changeImageHandler}
            accept=".jpg,.png,.jpeg"
          />
        </div>
      </div>

      {commentImage && (
        <div className={classes["img-preview"]}>
          <span title="delete photo" onClick={() => setCommentImage(null)}>
            <FontAwesomeIcon icon={faRectangleXmark} />
          </span>
          <img src={URL.createObjectURL(commentImage)} alt="post" />
        </div>
      )}
    </>
  );
};

export default memo(CreateDocForm);
