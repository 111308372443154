import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Posts from "../components/CommunityCenter/Posts";
import WritePost from "../components/CommunityCenter/WritePost";
import ErrorModal from "../components/ErrorModal/ErrorModal";
import Header from "../components/PersonalPage/Header";
import useHttp from "../hooks/http-hook";
import { setUserPosts, setUserPostsLoading } from "../store/postSlice";

import classes from "./PersonalPage.module.css";
import ContentEnter from "../components/UIElements/ContentEnter";

const PersonalPage = () => {
  const { user } = useSelector((state) => state.auth);
  const { userPosts, userPostsLoading } = useSelector((state) => state.post);
  const dispatch = useDispatch();
  const { userId } = useParams();
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserLoading, setCurrentUserLoading] = useState(true);

  const { error, sendRequest, clearError } = useHttp();

  const getPosts = useCallback(async () => {
    dispatch(setUserPostsLoading(true));
    try {
      const { data } = await sendRequest(`/api/v1/posts/${userId}`);

      dispatch(setUserPosts(data.posts));
      dispatch(setUserPostsLoading(false));
    } catch (error) {
      console.log(error);
    }
  }, [sendRequest, dispatch, userId]);

  useEffect(() => {
    getPosts();
  }, [getPosts]);

  useEffect(() => {
    const getCurrentUser = async () => {
      setCurrentUserLoading(true);
      try {
        const user = await sendRequest(`/api/v1/users/${userId}`);

        setCurrentUser(user.data);
      } catch (error) {
        console.log(error);
      }
      setCurrentUserLoading(false);
    };

    getCurrentUser();
  }, [userId, sendRequest]);

  return (
    <>
      <ErrorModal error={error} onCancel={clearError} />

      <ContentEnter
        show={!currentUserLoading && !userPostsLoading && currentUser !== null}
      >
        <div className={classes["personal-page"]}>
          <Header currentUser={currentUser} />

          {user._id === userId && (
            <div className={classes["write-post"]}>
              <WritePost />
            </div>
          )}

          <div className={classes.posts}>
            <Posts posts={userPosts} postsLoading={userPostsLoading} />
          </div>
        </div>
      </ContentEnter>
    </>
  );
};

export default PersonalPage;
