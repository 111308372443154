import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Dropdown from "./Dropdown";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCaretDown } from "@fortawesome/free-solid-svg-icons";

import classes from "./Navlinks.module.css";

function Navlinks({ onCloseNavSmall, showNavSmall }) {
  const { user } = useSelector((state) => state.auth);
  const [showDropdown, setShowDropdown] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      // Check if the click is outside of the menu
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        // Close the menu
        setShowDropdown(false);
      }
    };

    // Attach click event listener to the entire document
    document.addEventListener("click", handleDocumentClick);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const showDropdownHandler = () => setShowDropdown((prev) => !prev);

  return (
    <>
      <ul className={classes["nav"]}>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
            onClick={onCloseNavSmall}
          >
            Home
          </NavLink>
        </li>
        {user && (
          <li onClick={onCloseNavSmall}>
            <NavLink
              to="/train"
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              Train
            </NavLink>
          </li>
        )}

        <li>
          <NavLink
            to="ranking"
            onClick={onCloseNavSmall}
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
          >
            Ranking
          </NavLink>
        </li>

        <li onClick={onCloseNavSmall}>
          <NavLink
            to="/competitions"
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
          >
            Competitions
          </NavLink>
        </li>

        {user && (
          <li>
            <NavLink
              to="community"
              onClick={onCloseNavSmall}
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              Community
            </NavLink>
          </li>
        )}

        {!user && (
          <li onClick={onCloseNavSmall} className={classes.login}>
            <NavLink
              to="/login"
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              Login
            </NavLink>
          </li>
        )}

        {user && (
          <>
            <div
              className={classes.navNameDiv}
              ref={menuRef}
              onClick={showDropdownHandler}
            >
              <div className={classes.photo}>
                <img
                  crossOrigin="anonymous"
                  src={
                    user?.photo?.secure_url ||
                    `/public/img/usersImages/default.jpg`
                  }
                  alt={user.name}
                />
              </div>

              <h2 className={classes.myName}>
                <span>
                  {user.name.length <= 12 ? user.name : user.name.split(" ")[0]}
                </span>
                <FontAwesomeIcon icon={faSquareCaretDown} />
              </h2>

              <Dropdown
                showNavSmall={showNavSmall}
                onCloseNavSmall={onCloseNavSmall}
                show={showDropdown}
                onClick={showDropdownHandler}
              />
            </div>
          </>
        )}
      </ul>
    </>
  );
}

export default Navlinks;
