import { useEffect } from "react";
import CompetitionRegOpened from "../components/Home/CompetitionRegOpened";
import Matches from "../components/Home/Matches";
import Welcome from "../components/Home/Welcome";
import ContentEnter from "../components/UIElements/ContentEnter";
import useHttp from "../hooks/http-hook";
import { useSelector } from "react-redux";

import classes from "./Home.module.css";

let competitionsRegOpen = [];
const Home = () => {
  const { sendRequest } = useHttp();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const getRegOpenComp = async () => {
      try {
        const { data } = await sendRequest(
          `/api/v1/competitions/registration-opened-competitions`
        );

        if (user) {
          competitionsRegOpen = data.competitions.filter(
            (comp) => !comp.participants.includes(user._id)
          );
        } else {
          competitionsRegOpen = data.competitions;
        }
      } catch (error) {
        console.log(error);
      }
    };

    getRegOpenComp();
  }, [sendRequest, user]);

  return (
    <>
      <div className={classes.welcome}>
        <div className={classes.overlay}></div>

        <div className={classes.content}>
          <Welcome />

          <ContentEnter
            show={competitionsRegOpen && competitionsRegOpen.length > 0}
          >
            <CompetitionRegOpened competitionsRegOpen={competitionsRegOpen} />
          </ContentEnter>

          <Matches />
        </div>
      </div>
    </>
  );
};

export default Home;
