import classes from "./Participants.module.css";

const Participants = ({ participants }) => {
  return (
    <div className={classes.participants}>
      <h3>Participants</h3>

      <div className={classes["participants-list"]}>
        {participants.map((participant) => (
          <div key={participant._id} title={participant.name}>
            <img
              crossOrigin="anonymous"
              src={
                participant?.photo?.secure_url ||
                `/public/img/usersImages/default.jpg`
              }
              alt={participant.name}
            />

            <h4>{participant.name}</h4>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Participants;
