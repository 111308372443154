const CompetitionPaginationData = [
  {
    title: "Overview",
    to: "overview",
  },
  {
    title: "Ranking",
    to: "ranking",
  },
];

export default CompetitionPaginationData;
