import ReactRouterPrompt from "react-router-prompt";
import Modal from "../Modal/Modal";
import Button from "../FormElement/Button";

import classes from "./Prompt.module.css";

const Prompt = ({ isDirty }) => {
  return (
    <ReactRouterPrompt when={isDirty}>
      {({ isActive, onConfirm, onCancel }) => (
        <Modal
          show={isActive}
          header={"Are You Sure?!"}
          onCancel={onCancel}
          footer={
            <>
              <Button onClick={onConfirm} danger={true}>
                Ok
              </Button>
              <Button onClick={onCancel}>Cancel</Button>
            </>
          }
        >
          You want to leave this page?!
        </Modal>
      )}
    </ReactRouterPrompt>
  );
};

export default Prompt;
