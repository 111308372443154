import { useState } from "react";

import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import classes from "./Search.module.css";

const Search = () => {
  const [search, setSearch] = useState("");

  const searchHandler = (e) => {
    e.preventDefault();

    if (search.length < 1) {
      return;
    }

    console.log(search);
  };
  return (
    <form className={classes.search} onSubmit={searchHandler}>
      <input
        type="text"
        placeholder="Search..."
        onChange={(e) => setSearch(e.target.value)}
      />

      <button>
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </button>
    </form>
  );
};

export default Search;
