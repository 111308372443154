import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classes from "./ThreeDotsMenu.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave, faXmark } from "@fortawesome/free-solid-svg-icons";

const ThreeDotsMenu = ({ creator, postId, deletePost }) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <div className={classes.menu}>
      <ul>
        <li>
          <FontAwesomeIcon icon={faSave} /> Save post!
        </li>
        {(creator._id === user._id || user.role === "admin") && (
          <li onClick={() => deletePost(postId)}>
            <FontAwesomeIcon icon={faXmark} /> Delete Post!
          </li>
        )}
        {(creator._id === user._id || user.role === "admin") && (
          <li>
            <Link to={`/community/update/${postId}`}>
              <FontAwesomeIcon icon={faEdit} /> Edit Post!
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default ThreeDotsMenu;
