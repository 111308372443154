import { useSelector } from "react-redux";
import Button from "../FormElement/Button";

import classes from "./Welcome.module.css";

const Welcome = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <div className={classes.welcome}>
      <h1>Welcome To Strong Mind!</h1>
      <p>Keep your mind active and strong.</p>
      {user && (
        <div className={classes.btn}>
          <Button className={classes.button} to="train">
            Train
          </Button>
          <Button className={classes.button} to="ranking/national">
            Ranking
          </Button>
        </div>
      )}

      {!user && (
        <div className={classes.btn}>
          <Button className={classes.button} to="login">
            Login
          </Button>
        </div>
      )}
    </div>
  );
};

export default Welcome;
