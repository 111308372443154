const RankingPaginationData = [
  {
    title: "National",
    to: "national",
  },
  {
    title: "International",
    to: "international",
  },
  {
    title: "World",
    to: "world",
  },
  {
    title: "Review Table",
    to: "review-table",
  },
];

export default RankingPaginationData;
