import { useDispatch } from "react-redux";
import { setCompetitionId } from "../../store/resultSlice";

const SelectCompetition = ({ options }) => {
  const dispatch = useDispatch();

  return (
    <div>
      <label>Competition</label>
      <select
        value={localStorage.getItem("competitionId") || ""}
        onChange={(e) => {
          localStorage.setItem("competitionId", e.target.value);
          dispatch(setCompetitionId(e.target.value));
        }}
      >
        <option value=""></option>

        {options.map((opt) => (
          <option key={opt._id} value={opt._id}>
            {opt.competitionName} Season {opt.season}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectCompetition;
