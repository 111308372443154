import { faPen, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dateFormat from "dateformat";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useHttp from "../../hooks/http-hook";
import ErrorModal from "../ErrorModal/ErrorModal";
import WarningModel from "../WarningModel/WarningModel";

import classes from "./Competition.module.css";

const Competition = ({
  competition,
  removeDeletedCompetitionFromCompetitions,
}) => {
  const [showWarningModel, setShowWarningModel] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { isLoading, error, sendRequest, clearError } = useHttp();

  const deleteCompetitionHandler = async () => {
    try {
      await sendRequest(
        `/api/v1/competitions/delete-competition/${competition._id}`,
        "DELETE"
      );

      setShowWarningModel(false);
      removeDeletedCompetitionFromCompetitions(competition._id);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ErrorModal error={error} onCancel={clearError} />

      <WarningModel
        show={showWarningModel}
        message={`You want to delete ${competition.competitionName} season ${competition.season}?!`}
        onCancel={() => setShowWarningModel(false)}
        onConfirm={deleteCompetitionHandler}
        isLoading={isLoading}
      />

      <tr className={classes.competition}>
        <td>
          {dateFormat(competition.starts, "mmm dd")} -{" "}
          {dateFormat(competition.ends, "mmm d, yyyy")}
        </td>
        <td>
          {competition.competitionName} Season {competition.season}
        </td>
        <td className={classes.action}>
          <span>
            <Link
              to={`/competitions/${competition.slug}/${competition.season}/overview`}
            >
              Details
            </Link>
          </span>

          {user && user.role && user.role === "admin" && (
            <>
              <span className={classes.update}>
                <Link
                  to={`/competitions/update-competition/${competition.slug}/${competition.season}`}
                >
                  <FontAwesomeIcon icon={faPen} />
                </Link>
              </span>

              <span
                className={classes.delete}
                onClick={() => setShowWarningModel(true)}
              >
                <FontAwesomeIcon icon={faX} />
              </span>
            </>
          )}
        </td>
      </tr>
    </>
  );
};

export default Competition;
