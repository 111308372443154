const NamesInstructions = () => {
  return (
    <td>
      * One mistake = 0.
      <br />* Repeat mistake more than 3 times =
      <br /> Score - 0.5 each mistake.
    </td>
  );
};

export default NamesInstructions;
