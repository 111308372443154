import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Competition from "../components/Competitions/Competition";
import ErrorModal from "../components/ErrorModal/ErrorModal";
import Button from "../components/FormElement/Button";
import Loading from "../components/UIElements/Loading";
import useHttp from "../hooks/http-hook";

import classes from "./Competitions.module.css";

let competitionsExist = [];
const Competitions = () => {
  const { user } = useSelector((state) => state.auth);
  const [competitions, setCompetitions] = useState(competitionsExist);
  const { isLoading, error, sendRequest, clearError } = useHttp();

  useEffect(() => {
    const getCompetitions = async () => {
      try {
        const { data } = await sendRequest(`/api/v1/competitions`);

        competitionsExist = data.competitions;
        setCompetitions(data.competitions);
      } catch (error) {
        console.log(error);
      }
    };

    getCompetitions();
  }, [sendRequest]);

  const removeDeletedCompetitionFromCompetitions = (id) => {
    setCompetitions(competitions.filter((comp) => comp._id !== id));
  };

  if (isLoading && competitions.length === 0) return <Loading center />;

  return (
    <>
      <ErrorModal error={error} onCancel={clearError} />

      <div className={classes.competition}>
        {user && user.role && user.role === "admin" && (
          <div className={classes["create-competition-btn"]}>
            <Button className={classes.btn} to="create-competition">
              Create Competition
            </Button>
          </div>
        )}

        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Competition</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {competitions.length === 0 && !isLoading ? (
              <tr>
                <td colSpan={3}>No Competition Created Yet.</td>
              </tr>
            ) : (
              competitions.map((competition) => (
                <Competition
                  key={competition._id}
                  competition={competition}
                  removeDeletedCompetitionFromCompetitions={
                    removeDeletedCompetitionFromCompetitions
                  }
                />
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Competitions;
