import {
  faArrowUpRightDots,
  faHouseChimney,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import classes from "./CommunityBar.module.css";

const CommunityBar = ({ navigateToPage, currentPage }) => {
  return (
    <div className={classes["community-bar"]}>
      <div
        className={currentPage === 1 ? classes.active : undefined}
        onClick={() => navigateToPage(1)}
      >
        <FontAwesomeIcon icon={faUserGroup} />
      </div>

      <div
        className={currentPage === 2 ? classes.active : undefined}
        onClick={() => navigateToPage(2)}
      >
        <FontAwesomeIcon icon={faHouseChimney} />
      </div>

      <div
        className={currentPage === 3 ? classes.active : undefined}
        onClick={() => navigateToPage(3)}
      >
        <FontAwesomeIcon icon={faArrowUpRightDots} />
      </div>
    </div>
  );
};

export default CommunityBar;
